.PartnersWrap{
    min-height: 20vh;
    padding: 2em 0em;
    background-color: var(--white);
    margin-bottom: 2em;
}
.PartnersImgWrap{
    position: relative;
    height: 120px;
}
.PartnersImg{
    height: 100px;
}

@media (max-width: 768px){
    .PartnersImgWrap{
        height: auto;
    }
    .PartnersImg{
        width: 100%;
    }
}