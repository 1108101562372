.nav{
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo{
        height: 75px;
    }
    div{
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-grow: 1;
        a{
            color: var(--black);
            font-size: 1em;
            font-weight: bold;
            transition: var(--transition2);
            &:hover{
                transition: var(--transition2);
                color: var(--red);
            }
        }
    }
}

@media screen and (max-width: 768px){
    .nav{
        flex-direction: column;
        img{
            margin-top: 1em;
        }
        div{
            width: 100%;
            justify-content: space-around;
        }
    }
}
@media screen and (max-width: 450px){
    .nav{
        flex-direction: column;
        div{
            width: 100%;
    
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            a{
                margin: 0.5em;
            }
        }
    }
}