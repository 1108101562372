.wrap {
  padding: 2em 0  5em 0;
  .title {
    color: var(--red);
    font-size: 3em;
    b {
      color: var(--black);
    }
  }
  .item {
    background-color: var(--red);
    padding: 0.5em;
    margin: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: var(--radius18);
    svg {
      color: var(--white);
      height: 3em;
      width: 3em;
    }
    .counter {
      color: var(--white);
      font-size: 2em;
    }
    h3 {
      color: var(--white);
      font-size: 1.7em;
    }
  }
}

@media screen and (max-width: 768px) {
  .wrap {
    padding: 1em 0;
    .title {
      font-size: 2em;
    }
    .item {
      margin: 0.5em;
      svg {
        height: 1.5em;
        width: 1.5em;
      }
      .counter {
        font-size: 1.5em;
      }
      h3 {
        font-size: 1.1em;
      }
    }
  }
}
  