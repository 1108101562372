.main{
    background: var(--light-gray);
}
.wrap{
    padding: 5em 0;
    
    .item{
        padding: 1.5em;
        margin: 1em;
        display: flex;
        justify-content: center;
        transition: var(--transition6);
        border-radius: var(--radius18);
        background-color: var(--black);
        &:hover{
            transition: var(--transition4);
            background-color: var(--red);
        }
        &:hover .left svg{
            transition: var(--transition4);
            fill: var(--white);
        }
        &:hover .right h1, &:hover .right p{
            transition: var(--transition4);
            color: var(--white);
        }
        .left{
            svg{
                transition: var(--transition4);
                fill: var(--red);
                height: 2em;
                width: 2em;
            }
        }
        .right{
            padding-left: 1em;

            h1{
                transition: var(--transition4);
                color: var(--white);
                font-weight: bold;
            }
            p{
                transition: var(--transition4);
                color: var(--white);
                min-height: 90px;
            }
        }
    }
}

@media screen and (max-width: 768px){
    .wrap{
        padding: 2em 0;
        .item{
            padding: 1em;
            margin: 0.5em;
            .left{
                svg{
                    height: 1.5em;
                    width: 1.5em;
                }
            }
            .right{
                h1{
                    font-size: 1.3em;
                }
                p{
                    font-size: 1em;
                }
            }
        }
    }
}