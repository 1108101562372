.wrap{
    padding: 5em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img{
        width: 100%;
        margin-bottom: 2em;
    }
    h1{
        font-size: 3em;
    }
    p{
        font-size: 1.1em;
        text-align: center;
    }
    .about{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2em;
        h5{
            color: var(--black);
            font-size: 1.1em;
        }
        a{
            color: var(--red);
            text-decoration: none;
            font-size: 1em;
            margin: 1em 0;
        }
    }
}