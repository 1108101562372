.wrap{
    padding: 4em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .preImg{
        height: 40vh !important;
        object-fit: cover !important;
    }
    .imgWrap{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-direction: row;
        img{
            height: 200px;
            width: 100%;
            padding: 1em;
            object-fit: cover;
          
        }
        
    }
}

@media screen and (max-width: 768px) {
  .preImg{
      height: 30vh !important;
      object-fit: cover !important;
  }
  .imgWrap{
    img{
        object-fit: contain !important;
      
    }
    
}
}