@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600&display=swap');
html,
body {
  overflow-x: hidden !important;
  font-family: 'Quicksand', sans-serif !important;
  font-weight: 500;
  background-color: var(--light-gray) !important;
  scroll-behavior: smooth;
}
:root {
  --letter-spacing-1: 1px;
  --letter-spacing-2: 2px;
  --letter-spacing-3: 3px;
  --letter-spacing-4: 4px;
  --radius2: 2px;
  --radius4: 4px;
  --radius6: 6px;
  --radius8: 8px;
  --radius12: 12px;
  --radius18: 18px;
  --radiusCircle: 50%;
  --white: white;
  --light-gray: #ececec;
  --gray: #7c7c7c;
  --red: #a44040;
  --red-opacity: #a440409a;
  --black: #2c2c2c;
  --transition6: 0.6s ease-in-out all;
  --transition4: 0.4s ease-in-out all;
  --transition2: 0.2s ease-in-out all;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.ant-carousel .slick-prev {
  z-index: 99999 !important;
  left: 3vw !important;
  display: block;
  background-color: var(--red) !important;
  border-radius: var(--radiusCircle);
  color: var(--white) !important;
  height: 43px !important;
  width: 43px !important;
}

.ant-carousel .slick-next {
  z-index: 99999 !important;
  right: 3vw !important;
  display: block;
  background-color: var(--red) !important;
  border-radius: var(--radiusCircle);
  color: var(--white) !important;
  height: 43px !important;
  width: 43px !important;
}

.ant-carousel .slick-prev:before {
  content: "<";
  position: absolute;
  top: 31%;
  left: 26%;
  font-size: 20px;
  color: var(--white) !important;
}
.ant-carousel .slick-next::before {
  content: ">";
  position: absolute;
  top: 30%;
  left: 26%;
  font-size: 20px;
}
.slick-dots li button{
  padding: 5px !important;
}
.slick-active button{
  background-color: var(--red) !important;
}