.main{
    padding: 5em;
    h1{
        color: var(--red);
        text-align: center;
        font-size: 3em;
        font-weight: bold;
        b{
            color: var(--black);
        }
    }
    .projImg{
        width: 100%;
        padding: 0.5em;
    }
    .item{
        padding: 0.5em;
        position: relative;
        &:hover .center{
            transition: var(--transition4);
            transform: scale(1);
        }
        img{
            width: 100%;
            height: 300px;
            object-fit: cover;
        }
        .center{
            background-color: var(--red-opacity);
            transition: var(--transition4);
            transform: scale(0);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: absolute;
            inset: 0 0 0 0;
            h1{
                color: var(--white);
                font-size: 1.5em;
                text-align: center;
            }
            h6{
                color: var(--white);
                font-size: 1em;
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: 768px){
    .main{
        padding: 2em;
        h1{
            font-size: 2em;
        }
        .projImg{
            display: none;
        }
        .item{
           
           img{
               max-width: 100%;
               height: 200px;
           }
           .center{
            transform: scale(1);
            h1{
                font-size: 1.3em;
            }
            h6{
                color: var(--white);
                font-size: 1em;
                text-align: center;
            }
           }
        }
    }
}