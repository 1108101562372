.main{
    background: var(--black);
    .wrap{
        padding: 2em 0 5em 0;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .title{
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 0 4vw;
            margin-bottom: 2em;
            h1{
                color: var(--white);
                font-size: 3em;
                font-weight: bold;
                text-align: center;
            }
            h1 b{
                color: var(--red);
                
                font-weight: bold;
            }
            p{
                text-align: center;
                color: var(--light-gray);
            }
        }
        .item{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 1em;
            padding: 1.5em;
            background: var(--red);
            border-radius: var(--radius18);
            transition: var(--transition6);
            &:hover{
                transition: var(--transition4);
                background: var(--white);
            }
            &:hover a{
                transition: var(--transition4);
                color: var(--red);
            }
            &:hover p{
                transition: var(--transition4);
                color: var(--black);
            }
            &:hover button{
                color: var(--white);
                background: var(--red);
                transition: var(--transition4);
            }
            &:hover svg{
                transition: var(--transition4);
                fill: var(--red);
            }
            a{
                color: var(--white);
                font-size: 2em;
                font-weight: bold;
                text-align: center;
            }
            p{
                text-align: center;
                color: var(--white);
                padding: 1em;
                min-height: 180px;
            }
             .readMore{
                background: var(--white);
                color: var(--red);
                border: none;
                border-radius: var(--radius6);
                
                font-size: 1.1em;
                padding: 0.5em;
                font-weight: bold;
                cursor: pointer;
                transition: var(--transition4);
                
            }
            svg{
                transition: var(--transition4);
                fill: var(--white);
                padding-right: 0.5em;
                height: 3em;
                width: 3em;
                position: absolute;
                right: 0;
                bottom: 0;
                opacity: 0.3;
            }
        }
    }
}



@media screen and (max-width: 768px){
    .main{
        .wrap{
            padding: 2em 0 5em 0;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .title{
                h1{
                    font-size: 2em;
                }
            }
            .item{
                a{
                    font-size: 1.5em;
                }
                p{
                    font-size: 1em;
                }
            }
        }
    }
    
}

@media screen and (min-width: 768px){
    .item{
        height: 400px;
    }
}