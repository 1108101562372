.container{
    position: absolute;
    top: 35%;
    margin: 0 auto;
    width: 100vw;
}
.CarouselImg{
    width: 100vw;
    height: 80vh;
    object-fit: cover;
    filter: brightness(0.4);
}
.textWrap{
   
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .title{
        color: var(--white);
        font-size: 4em;
        font-weight: bold;
    }
    .description{
        color: var(--white);
        font-size: 1.8em;
        border-bottom: 1px solid var(--grey);
        display: inline;
        padding-bottom: 0.3em;
    }
    a{
        cursor: pointer;
        background-color: var(--red) !important;
        color: var(--white);
        font-weight: bold;
        margin-top: 7em;
        font-size: 1.1em;
        padding: 0.5em;
        border: 2px solid var(--red);
        border-radius: var(--radius8);
        transition: var(--transition4);
        &:hover{
            transition: var(--transition4);
            background-color: var(--white) !important;
            color: var(--red);
            border: 2px solid var(--white);
        }
    }
}

@media screen and (max-width: 1350px){
    .title{
        font-size: 2em !important;
    }
    .description{
        font-size: 1.5em !important;
        padding: 2em 10em !important;
    }
}
@media screen and (max-width: 768px){
    .container{
        top: 22% !important;
        button{
            display: none;
        }
    }
    .CarouselImg{
        height: 50vh;
    }
    .title{
        font-size: 2em !important;
    }
    .description{
        margin-top: 3em;
        font-size: 1.1em !important;
        padding: 0 3em !important;
        text-align: center;
    }
}